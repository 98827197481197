import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Menu from "../components/Menu"

const SecondPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Menu data={data} />
  </Layout>
);

export default SecondPage

export const query = graphql`  
  query IndexQuery {
    allStrapiArticle(sort: {order: ASC, fields: Order}) {
      edges {
        node {
          id
          Title
          Menu
          Link
          Published
          Order
        }
      }
    }
  }
`;
