import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Header from '../Header';

import styles from './Menu.module.css'

const Menu = ({ data }) => (
  <div className={styles.self}>
    <Header />
    <ul className={styles.list}>
      {data.allStrapiArticle.edges.map(({ node: { id, Published, Menu, Title, Link: link } }) => (
        <li key={id} className={`${styles.item} ${!Published && styles.unpublished}`}>
          <h2>
            {Published ?
              <Link to={`/${link}`} className={styles.itemLink}>{Menu || Title}</Link> :
              <span className={styles.itemLink}>{Menu || Title}</span>}
          </h2>
        </li>
      ))}
    </ul>
  </div>
);

Menu.propTypes = {
  data: PropTypes.object,
};

export default Menu
